import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { OrderHistory } from '../model/order-history.model';
import { OrderItemHistory } from '../model/order-item-history';
import { OrderValidation } from '../model/order-validation.model';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService extends BaseService<string> {
  protected apiUrl: string;

  public orderSearchPeriod: Array<{ value: string; text: string }> = [
    { value: 'ThirtyDays', text: '30 days' },
    { value: 'NinetyDays', text: '90 days' },
    { value: 'SixMonths', text: '6 Months' },
    { value: 'AYear', text: '1 year' },
    { value: 'LastYear', text: `${new Date().getFullYear() - 1}` },
    { value: 'AYearBeforeLast', text: `${new Date().getFullYear() - 2}` },
  ];

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.orderHistory;
  }

  getHistory(range: string, page: number, limit: number): Observable<OrderHistory> {
    return this.httpGet(`${this.apiUrl}/get?range=${range}&page=${page}&limit=${limit}`);
  }

  getOrder(orderNumber: number, range: string): Observable<OrderHistory> {
    return this.httpGet(`${this.apiUrl}/getOrder?range=${range}&orderNumber=${orderNumber}`);
  }

  getItems(criteria: string, range: string, page: number, limit: number): Observable<OrderItemHistory> {
    return this.httpGet(`${this.apiUrl}/getItems?range=${range}&criteria=${criteria}&page=${page}&limit=${limit}`);
  }

  checkOrderValidity(name: string, orderNumber: number): Observable<OrderValidation> {
    return this.httpGet(`${this.apiUrl}/checkValidity?name=${name}&orderNumber=${orderNumber}`);
  }
}
