import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/authorization';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { FloatingCart } from '../../model/floating-cart.model';
import { FloatingCartCacheService } from '../../service/floating-cart-cache.service';
import { FloatingCartService } from '../../service/floating-cart.service';
import { HeaderService } from '../../service/header.service';

@Component({
  selector: 'lef-header-floating-cart',
  templateUrl: './header-floating-cart.component.html',
  styleUrls: ['./header-floating-cart.component.scss'],
})
export class HeaderFloatingCartComponent implements OnInit, OnDestroy {
  itemCount = 0;
  floatingCart: FloatingCart = new FloatingCart();
  isLoadingFloatingCart = false;
  ttl = 3;
  cacheName = 'floating-cart';
  countChangeSubscription: Subscription;
  @ViewChild('floatingCartContent') floatingCartContent: ElementRef;

  get showHeaders(): boolean {
    return this.headerService.showFullHeader;
  }

  constructor(
    private readonly floatingCartService: FloatingCartService,
    private readonly cache: FloatingCartCacheService,
    private readonly cookieService: CartCookieService,
    private readonly authorizationService: AuthorizationService,
    public readonly headerService: HeaderService,
    protected readonly router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.refreshItemsInCartCounter();
    this.subscribeToCountChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeToCountChange();
  }

  subscribeToCountChange() {
    this.countChangeSubscription = this.floatingCartService.itemsCountChange.subscribe(() => this.refreshItemsInCartCounter());
  }

  unsubscribeToCountChange() {
    if (this.countChangeSubscription) {
      this.countChangeSubscription.unsubscribe();
      this.countChangeSubscription = null;
    }
  }

  viewFloatingCart() {
    if (!this.floatingCartContent.nativeElement.classList.contains('show')) {
      const cart = this.cache.get(this.cacheName);
      if (cart) {
        this.floatingCart = cart;
        this.registerItemNavigationListener();
        this.cdr.detectChanges();
      } else {
        this.loadFloatingCart();
      }
    }
  }

  loadFloatingCart() {
    this.isLoadingFloatingCart = true;
    this.floatingCartService.getFloatingCart().subscribe((result) => {
      this.cache.set(this.cacheName, result, this.ttl);
      this.floatingCart = result;
      this.isLoadingFloatingCart = false;
      this.registerItemNavigationListener();
      this.refreshItemsInCartCounter();
    });
  }

  refreshItemsInCartCounter() {
    this.itemCount = this.cookieService.getItemsInCartCount()
    this.cdr.detectChanges();
  }

  findMyOrder() {
    const isLoggedIn = this.authorizationService.isLoggedIn();
    const target = isLoggedIn ? '/account/orderhistory' : '/findmyorder';
    this.router.navigateByUrl(target);
  }

  registerItemNavigationListener() {
    const menuDelay = 1000;
    setTimeout(() => {
      $('.floating-cart-item a')
        .off('click')
        .on('click', (e) => {
          location.href = (e.currentTarget as HTMLAnchorElement).href;
          return false;
        });
    }, menuDelay);
  }
}
