import { OrderItem } from './order-item.model';
import { OrderPayment } from './order-payment.model';
import { OrderShipment } from './order-shipment.model';
import { ShippingMethod } from './shipping-method.model';
import { WarehouseExpectedSummary } from './warehouse-expected-summary.model';

export class OrderDetail {
  itemsCount: number;
  orderNumber: string;
  orderType: string;
  trackingNumber: string;
  isReturn: boolean;
  orderSource: string;
  orderDate: string;
  total: string;
  subTotal: string;
  shippingCost: string;
  prepaidLabel: string;
  refundTotal: string;
  dollarsEarned: string;
  dollarsStatus: string;
  shipments: OrderShipment[];
  eligibleForReturns: boolean;
  isReturnAllow: boolean;
  returnNotAvailable: boolean;
  showCustomMessage: boolean;
  customMessage: string;
  isCustomerAllowToReturnOnline: boolean;
  collapsed: boolean;
  shippingMethods: ShippingMethod[] = [];
  payments: OrderPayment[] = [];
  returnPayments: OrderPayment[] = [];
  returnItems: OrderItem[] = [];
  warehouseExpected: WarehouseExpectedSummary[] = [];
}
