import * as braintree from 'braintree-web';
import { Subject } from 'rxjs';
import { CreditCard, CreditCardValidationResult } from './payment-credit-card';

export interface ICreditCardProcessor {
  onCreditCardValidation: Subject<CreditCardValidationResult>;
  onInitialized: Subject<boolean>;
  initCreditCardFormFields(): void;
  validateCreditCard(postalCode: string, billingType: string, usePostalCodeInCreditCardValidation: boolean): void;
  resetFields(): void;
}

export class EmptyCreditCardProcessor implements ICreditCardProcessor {
  onCreditCardValidation: Subject<CreditCardValidationResult> = new Subject<CreditCardValidationResult>();
  onInitialized: Subject<boolean> = new Subject<boolean>();
  initCreditCardFormFields(): void {}
  validateCreditCard(postalCode: string, billingType: string, usePostalCodeInCreditCardValidation: boolean): void {}
  resetFields(): void {}
}

export class BraintreeCreditCardProcessor implements ICreditCardProcessor {
  authorization: string;
  hostedFieldsInstance: braintree.HostedFields;
  deviceData = '';

  onCreditCardValidation: Subject<CreditCardValidationResult> = new Subject<CreditCardValidationResult>();
  onInitialized: Subject<boolean> = new Subject<boolean>();

  constructor(authorization: string) {
    this.authorization = authorization;
  }

  resetFields(): void {
    if (this.hostedFieldsInstance) {
      this.hostedFieldsInstance.clear('number');
      this.hostedFieldsInstance.clear('cvv');
      this.hostedFieldsInstance.clear('expirationDate');
    }
  }

  initCreditCardFormFields(): void {
    setTimeout(() => {
      braintree.client
        .create({
          authorization: this.authorization,
        })
        .then((clientInstance: any) => {
          braintree.dataCollector
            .create({
              client: clientInstance,
            })
            .then((dataCollectorInstance: any) => {
              this.deviceData = dataCollectorInstance.deviceData;
            })
            .catch((err: any) => {
              console.log(err);
            });

          braintree.hostedFields
            .create({
              client: clientInstance,
              styles: {
                input: {
                  'font-size': '16px',
                },
              },
              fields: {
                number: {
                  selector: '#cc-number',
                  placeholder: '**** **** **** ****',
                },
                cvv: {
                  selector: '#cc-cvv',
                  placeholder: '***',
                },
                expirationDate: {
                  selector: '#cc-expiration',
                  placeholder: 'MM/YY',
                },
              },
            })
            .then((hostedFieldsInstance: any) => {
              this.hostedFieldsInstance = hostedFieldsInstance;

              hostedFieldsInstance.on('focus', (event: any) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                label.classList.remove('filled'); // added and removed css classes
                // can add custom code for custom validations here
              });

              hostedFieldsInstance.on('blur', (event: any) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field); // fetched label to apply custom validations
                // can add custom code for custom validations here
              });

              hostedFieldsInstance.on('empty', (event: any) => {
                const field = event.fields[event.emittedBy];
                // can add custom code for custom validations here
              });

              hostedFieldsInstance.on('validityChange', (event: any) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                field.container.classList.remove('is-valid');
                field.container.classList.remove('is-invalid');
                label.classList.remove('text-danger');
                if (field.isValid) {
                  field.container.classList.add('is-valid');
                } else if (field.isPotentiallyValid) {
                } else {
                  label.classList.add('text-danger');
                  field.container.classList.add('is-invalid');
                }
              });

              this.onInitialized.next(true);
            });
        });
    }, 10);
  }

  // Fetches the label element for the corresponding field
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
  }

  validatePostalCode(postalCode: string, billingType: string, usePostalCodeInCreditCardValidation: boolean): boolean {
    if (!usePostalCodeInCreditCardValidation) {
      return true;
    }
    let postalCodeFieldValid = true;
    if (billingType === 'local') {
      postalCodeFieldValid = !!postalCode;
    }
    const postalCodeField = document.querySelector('#cc-postal-code-container');
    if (postalCodeFieldValid) {
      postalCodeField.classList.remove('is-invalid');
    } else {
      postalCodeField.classList.add('is-invalid');
    }
    return postalCodeFieldValid;
  }

  validateCreditCard(postalCode: string, billingType: string, usePostalCodeInCreditCardValidation: boolean): void {
    let formIsInvalid = !this.validatePostalCode(postalCode, billingType, usePostalCodeInCreditCardValidation);
    const state = this.hostedFieldsInstance.getState();

    Object.keys(state.fields).forEach((field) => {
      if (!state.fields[field].isValid) {
        $(state.fields[field].container).addClass('is-invalid');
        formIsInvalid = true;
      }
    });

    if (formIsInvalid) {
      this.onCreditCardValidation.next({ isSuccess: false, response: '' });
      return;
    }

    this.hostedFieldsInstance.tokenize((tokenizeErr: any, payload: any) => {
      if (tokenizeErr) {
        this.onCreditCardValidation.next({ isSuccess: false, response: tokenizeErr });
        return;
      }
      const country = payload.binData.countryOfIssuance;
      if (usePostalCodeInCreditCardValidation && country === 'USA' && billingType !== 'local') {
        this.onCreditCardValidation.next({
          isSuccess: false,
          response: 'Billing address associated with the Credit Card will require a Zip Code.',
        });
        return;
      }

      const card: CreditCard = {
        first6: payload.details.bin,
        last4Digits: payload.details.lastFour,
        expirationMonth: payload.details.expirationMonth,
        expirationYear: payload.details.expirationYear,
        type: payload.details.cardType,
        countryOfIssuance: country,
        postalCode,
        nonce: payload.nonce,
        setAsDefault: false,
        deviceData: this.deviceData,
      };
      this.onCreditCardValidation.next({ isSuccess: true, response: '', creditCard: card });
    });
  }
}
