import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMenuLink } from '../../model/menu-link';

@Component({
  selector: 'lef-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss'],
})
export class HeaderAccountComponent implements OnInit {
  @Input() username = '';
  @Input() menuLinks: IMenuLink[] = [];
  @Input() menuOnHeader: boolean = false;

  @Output() linkClick: EventEmitter<IMenuLink> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleLinkClick(link: IMenuLink): void {
    this.linkClick.emit(link);
  }

  toggleAccountMenu() {
    var accountMenuPanel = document.getElementById("accountMenuPanel");
    if (accountMenuPanel.style.display == "none") {
      accountMenuPanel.style.display = "block";

      let iconElement = document.getElementById("accountMenuOpenCloseIcon");
      if (iconElement) {
        iconElement.classList.remove("lef-chevron-right");
        iconElement.classList.add("lef-chevron-down");
      }

      let overlayElement = document.getElementById("sideMenuOverlay");
      if (overlayElement) overlayElement.classList.add("show-overlay");

    } else {
      accountMenuPanel.style.display = "none";

      let iconElement = document.getElementById("accountMenuOpenCloseIcon");
      if (iconElement) {
        iconElement.classList.add("lef-chevron-right");
        iconElement.classList.remove("lef-chevron-down");
      }

      let overlayElement = document.getElementById("sideMenuOverlay");
      if (overlayElement) overlayElement.classList.remove("show-overlay");
    }
  }
}
