import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { IAutoShip } from '../../model/auto-ship';
import { IAutoShipDelivery } from '../../model/auto-ship-delivery';
import { IAutoShipItem } from '../../model/auto-ship-item';
import { IAutoShipSchedule, IAutoShipScheduleDelivery, IAutoShipScheduleShipment } from '../../model/auto-ship-schedule';

@Component({
  selector: 'lef-auto-ship-schedule',
  templateUrl: './auto-ship-schedule.component.html',
  styleUrls: ['./auto-ship-schedule.component.scss'],
})
export class AutoShipScheduleComponent implements OnInit {
  @Input()
  autoShip: IAutoShip;

  schedule: IAutoShipSchedule = { deliveries: [] };

  datesToShow = 6;
  dateFormat = 'MM/DD/YYYY';
  selectedDeliveryIndex = 0;

  constructor() {}

  ngOnInit() {
    if (!this.autoShip?.activeDeliveries?.length) {
      return;
    }

    this.setSchedule();
  }

  selectDelivery(index: number) {
    this.selectedDeliveryIndex = index;
  }

  private setSchedule() {
    this.autoShip?.activeDeliveries.forEach((delivery) => {
      this.schedule.deliveries.push(this.getScheduleDelivery(delivery));
    });
  }

  private getScheduleDelivery(delivery: IAutoShipDelivery): IAutoShipScheduleDelivery {
    const scheduleDelivery: IAutoShipScheduleDelivery = {
      alias: delivery.alias,
      shipments: this.getDeliveryShipments(delivery),
    };

    return scheduleDelivery;
  }

  private getDeliveryShipments(delivery: IAutoShipDelivery): IAutoShipScheduleShipment[] {
    this.setItemsFutureShipDates(delivery);

    const shipmentDates: string[] = this.getShipmentDates(delivery);
    const shipments: IAutoShipScheduleShipment[] = [];

    shipmentDates.forEach((shipmentDate) => {
      shipments.push(this.getDeliveryShipment(shipmentDate, delivery));
    });

    return shipments;
  }

  private getDeliveryShipment(shipmentDate: string, delivery: any): IAutoShipScheduleShipment {
    const shipmentItems = this.getShipmentItems(shipmentDate, delivery);
    const shipment: IAutoShipScheduleShipment = {
      date: shipmentDate,
      items: shipmentItems,
      total: shipmentItems.map((i) => i.itemPrice * i.quantity).reduce((a, b) => a + b),
    };

    return shipment;
  }

  private getShipmentItems(shipmentDate: string, delivery: any): IAutoShipItem[] {
    return delivery.items.filter((i) => i.futureShipDates.indexOf(shipmentDate) >= 0);
  }

  private setItemsFutureShipDates(delivery: IAutoShipDelivery) {
    delivery.items.forEach((item) => {
      this.setItemFutureShipDates(item, delivery);
    });
  }

  private setItemFutureShipDates(item: IAutoShipItem, delivery: IAutoShipDelivery) {
    item.futureShipDates = [];

    let nextShipDate = moment(item.nextShipDate);
    item.futureShipDates.push(nextShipDate.format(this.dateFormat));

    for (let i = 1; i < this.datesToShow; i++) {
      let newDate = nextShipDate.clone();
      newDate.add(item.interval, 'month');

      if (newDate.date() !== delivery.dayOfMonth) {
        if (newDate.daysInMonth() >= delivery.dayOfMonth) {
          newDate = moment().year(newDate.year()).month(newDate.month()).date(delivery.dayOfMonth);
        } else if (newDate.date() !== newDate.daysInMonth()) {
          newDate = moment().year(newDate.year()).month(newDate.month()).date(newDate.daysInMonth());
        }
      }

      nextShipDate = newDate;
      item.futureShipDates.push(nextShipDate.format(this.dateFormat));
    }
  }

  private getShipmentDates(delivery: any): string[] {
    const dates: string[] = [].concat
      .apply(
        [],
        delivery.items.map((i) => i.futureShipDates)
      )
      .sort((a, b) => {
        const aDate = Date.parse(a);
        const bDate = Date.parse(b);

        if (aDate > bDate) {
          return 1;
        } else if (aDate < bDate) {
          return -1;
        } else {
          return 0;
        }
      })
      .filter((item, pos, ary) => {
        return !pos || item !== ary[pos - 1];
      });

    return dates.length > this.datesToShow ? dates.slice(0, this.datesToShow) : dates;
  }
}
