import { ILabSubject } from 'src/app/blood-lab/model';
import { ILabSubjectInfo } from 'src/app/blood-lab/model/lab-subject-info';
import { ICartExtendedMessage, ICartItem, ICartMessage, ICartPromotionMessage, ICartSectionMessage } from 'src/app/cart-common/model';
import { IPaymentInfo, PaymentInfo } from 'src/app/checkout/model';
import { IPaymentOption } from 'src/app/payment/model';
import { IShippingSummary } from 'src/app/shipping/model';
import { CartPromotionCategory } from './cart-promotion-category';

export interface ICart {
  shipping: IShippingSummary;
  items: ICartItem[];
  messages: ICartMessage[];
  extendedMessages: ICartExtendedMessage[];
  marketingDiscountItem: ICartItem;
  marketingMessages: ICartPromotionMessage[];
  globalMessages?: ICartSectionMessage;
  labSubjects?: ILabSubjectInfo[];
  isEmployee: boolean;
  showMarketingMessage: boolean;
  marketingDescription: string;
  isPromoEnteredExclusive: boolean;
  redirectUrl: string;

  savings: number;
  discount: number;
  credit: number;
  lePremierRewardAmount: number;
  lePremierRewardAmountApplied: number;
  rewardsApplied: number;
  pendingRewardDollars: number;
  giftCardPaymentApplied: number;
  totalItems: number;
  totalFreight: number;
  totalShipping: number;
  subTotal: number;
  totalOrder: number;
  amountDue: number;
  amountDueToday: number;
  quantity: number;
  paymentInfo: IPaymentInfo;
  paymentOptionSelected: IPaymentOption;
  totalPayment: number;
  showPremierOffer: boolean;
  isRenewalCandidate: boolean;
  isVipPremierCustomer: boolean;
  isGuestCheckout: boolean;
  guestCreatedWebAccount: boolean;
  hasBackOrder: boolean;
  isOrderPlaced: boolean;
  orderNumber: number;
  orderDate: string;
  orderTime: string;
  orderCustomerNumber: string;
  orderCustomerEmail: string;
  autoShipDayOfMonth: number;
  hasAutoShipItems: boolean;
  exclusiveMarketingCode: string;
  checkProp65Consent: boolean;
  requiresProp65Consent: boolean;
  isSuperSale: boolean;
  promotionCategories?: CartPromotionCategory[];
  isPremierBannerAvailable?: boolean;
  isPromoAvailable?: boolean;
}

export class Cart implements ICart {
  isVipPremierCustomer: boolean;
  isEmployee: boolean;
  isRenewalCandidate: boolean;
  savings: number;
  discount: number;
  credit: number;
  lePremierRewardAmount: number;
  lePremierRewardAmountApplied: number;
  rewardsApplied: number;
  pendingRewardDollars: number;
  giftCardPaymentApplied: number;
  totalItems: number;
  totalFreight: number;
  totalShipping: number;
  subTotal: number;
  totalOrder: number;
  amountDue: number;
  amountDueToday: number;
  quantity: number;
  shipping: IShippingSummary = null;
  items: ICartItem[] = [];
  messages: ICartMessage[] = [];
  extendedMessages: ICartExtendedMessage[];
  globalMessages?: ICartSectionMessage;
  marketingDiscountItem: ICartItem;
  marketingMessages: ICartPromotionMessage[] = [];
  labSubjects?: ILabSubject[];
  showMarketingMessage: boolean;
  marketingDescription: string;
  isPromoEnteredExclusive: boolean;
  redirectUrl: string;
  paymentInfo: PaymentInfo = new PaymentInfo();
  paymentOptionSelected: IPaymentOption;
  totalPayment: number;
  showPremierOffer: boolean;
  isGuestCheckout: boolean;
  guestCreatedWebAccount: boolean;
  hasBackOrder: boolean;
  isOrderPlaced: boolean;
  orderNumber: number;
  orderDate: string;
  orderTime: string;
  orderCustomerNumber: string;
  orderCustomerEmail: string;
  autoShipDayOfMonth: number;
  hasAutoShipItems: boolean;
  exclusiveMarketingCode: string;
  checkProp65Consent: boolean;
  requiresProp65Consent: boolean;
  isSuperSale: boolean;
  promotionCategories?: CartPromotionCategory[] = [];
  isPremierBannerAvailable?: boolean;
  isPromoAvailable?: boolean;
}
