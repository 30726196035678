import { Component, OnInit, Renderer2 } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthorizationService } from 'src/app/authorization';
import { ICart } from 'src/app/cart-common/model/cart';
import { ICartItem } from 'src/app/cart-common/model/cart-item';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { Constants } from '../../model/constants';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    criteo_q: any;
  }
}

@Component({
  selector: 'lef-criteo',
  templateUrl: './criteo.component.html',
  styleUrls: ['./criteo.component.scss'],
})
export class CriteoComponent implements OnInit {
  private isMobile: boolean;
  private isTablet: boolean;
  constructor(
    private readonly renderer: Renderer2,
    private readonly config: CoreConfiguration,
    private readonly trackingService: TrackingService,
    private readonly authService: AuthorizationService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    if (this.config.environment.enableCriteoTracking && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.targeting)) {
      this.addJavaScriptResources();
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      this.trackingService.viewCart.subscribe({ next: (cart) => this.onViewCart(cart) });
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();
    }
  }
  addJavaScriptResources() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//static.criteo.net/js/ld/ld.js';
    this.renderer.appendChild(document.body, script);
    window.criteo_q = window.criteo_q || [];
  }

  onOrderPlaced(cart: ICart) {
    const items = cart.items.filter((i) => i.itemNumber !== 'FREIGHT').map((i) => new CriteoItemData(i));
    const uiUserType = this.authService.isWholesaler() ? 'wholesale' : 'standard';

    window.criteo_q.push(
      { event: 'setAccount', account: 23162 },
      { event: 'setEmail', email: this.trackingService.storage.get(Constants.Email) },
      { event: 'setSiteType', type: this.determineCriteoDevice() },
      { event: 'setData', ui_usertype: uiUserType },
      { event: 'trackTransaction', id: cart.orderNumber, item: items }
    );
  }

  onViewCart(cart: ICart) {
    const items = cart.items.filter((i) => i.itemNumber !== 'FREIGHT').map((i) => new CriteoItemData(i));
    const uiUserType = this.authService.isWholesaler() ? 'wholesale' : 'standard';
    window.criteo_q.push(
      { event: 'setAccount', account: 23162 },
      { event: 'setEmail', email: this.trackingService.storage.get(Constants.Email) },
      { event: 'setSiteType', type: this.determineCriteoDevice() },
      { event: 'setData', ui_usertype: uiUserType },
      { event: 'viewBasket', item: items }
    );
  }

  determineCriteoDevice(): string {
    if (this.isMobile) {
      return 'm';
    } else if (this.isTablet) {
      return 't';
    }
    return 'd';
  }
}
class CriteoItemData {
  id: string;
  price: number;
  quantity: number;
  constructor(item: ICartItem) {
    this.id = item.itemNumber;
    this.price = item.yourPrice;
    this.quantity = item.quantity;
  }
}
