import { Injectable } from '@angular/core';
import * as Coveo from 'coveo-search-ui';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Injectable({
  providedIn: 'root',
})
export class CoveoService {
  constructor(private readonly config: CoreConfiguration) { }

  public initSearchBox = (searchBox: HTMLElement, resultPageUrl: string): void => {
    this.createEndpoint();
    this.subscribeToRedirectEvent(searchBox, resultPageUrl);
    this.subscribeToBuildingQueryEvent(searchBox);

    Coveo.initSearchbox(searchBox, resultPageUrl);

    this.overrideAttributes(searchBox);
  }

  public initSearchInterface = (searchInterface: HTMLElement, externalSearchBox: HTMLElement = null): void => {
    this.setLoggerLevel();
    this.createEndpoint();
    this.subscribeToBuildingQueryEvent(searchInterface);

    if (externalSearchBox) {
      Coveo.init(searchInterface, { externalComponents: [externalSearchBox] });
    } else {
      Coveo.init(searchInterface);
    }
  }

  private setLoggerLevel() {
    if (!Coveo?.Logger) {
      return;
    }

    let level = this.config.coveoConfiguration.loggerLevel;
    if (!level || level < 1 || level > 6) {
      level = 6;
    }

    Coveo.Logger.level = this.config.coveoConfiguration.loggerLevel;
  }

  private createEndpoint = (): void => {
    if (Coveo && Coveo.SearchEndpoint && Coveo.SearchEndpoint.endpoints && Coveo.SearchEndpoint.endpoints.default) {
      // EndPoint is already initialized;
      return;
    }

    Coveo.SearchEndpoint.configureCloudV2Endpoint(
      this.config.coveoConfiguration.organization,
      this.config.coveoConfiguration.apiKey,
      this.config.coveoConfiguration.restApiUrl,
      {
        queryStringArguments: {
          searchHub: this.config.coveoConfiguration.searchHub,
        },
      }
    );
  }

  private initCustomComponents = (): void => {
    // Initialize Coveo Custom Components
    // Initialization.registerAutoCreateComponent(DemoComponent);
  }

  private subscribeToRedirectEvent = (rootElement: HTMLElement, resultPageUrl: string): void => {
    Coveo.$$(rootElement).on('afterInitialization', () => {
      Coveo.$$(rootElement).on('beforeRedirect', (e, data) => {
        data.cancel = true;
        const currentQuery = Coveo.state(rootElement, 'q');
        const redirectionUrl = `${resultPageUrl}#q=${currentQuery}`;

        window.location.href = redirectionUrl;
      });
    });
  }

  private subscribeToBuildingQueryEvent = (rootElement: HTMLElement): void => {
    if (!rootElement || !this.config.coveoConfiguration.queryExpressions || !this.config.coveoConfiguration.queryExpressions.length) {
      return;
    }
    const queryExpressions = this.config.coveoConfiguration.queryExpressions;

    Coveo.$$(rootElement).on('afterInitialization', () => {
      Coveo.$$(rootElement).on('buildingQuery', (e, args) => {
        const queryBuilder = args.queryBuilder;
        if (!queryBuilder || !queryBuilder.advancedExpression) {
          return;
        }

        queryExpressions.forEach((qExp) => {
          if (qExp.isNotEqualExpression) {
            queryBuilder.advancedExpression.addFieldNotEqualExpression(qExp.field, qExp.values);
          } else {
            queryBuilder.advancedExpression.addFieldExpression(qExp.field, qExp.operator, qExp.values);
          }
        });
      });
    });
  }

  private overrideAttributes(searchBox: HTMLElement): void {
    searchBox?.children[2].removeAttribute('role');
    searchBox?.children[2].removeAttribute('tabindex');
    searchBox?.children[2].setAttribute("href", "");
    searchBox?.children[2].setAttribute("onclick", "return false");
    let spanElements = searchBox?.children[2].getElementsByTagName('span');

    for (var i = 0; i < spanElements?.length; i++) {
      const svgElements = spanElements[i].getElementsByTagName('svg');
      for (var j = 0; j < svgElements?.length; j++) {
        svgElements[j].removeAttribute('aria-label');
        svgElements[j].setAttribute('aria-hidden', 'true');
        svgElements[j].removeAttribute('role');
      }
    }
  }
}
