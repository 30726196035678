<div #cartContainer class="px-2 px-lg-0">
  <lef-cart-extended-message [messages]="cart.extendedMessages"></lef-cart-extended-message>
  <lef-section-title [title]="'My Cart'" titleClass="d-none d-md-block" [promoteToH1]="true"></lef-section-title>
  <div class="os-mobile-header d-md-none">
    <h1 class="h3">My Cart</h1>
    <p>{{cart.quantity}} Item(s) <strong class="pl-2">{{cart.totalOrder | currency}}</strong></p>
  </div>
  <div *ngIf="cart.items.length > 0;else emptyCart" class="row position-relative">
    <div class="col-md-7 col-lg-8 cart-comp-lt mob-pad-0">
      <lef-scroll-container [scrollOffset]="55" [isResponsive]="true" [maxScreenSize]="screenSizes.MD">
        <div class="d-md-none float-cart-action">
          <button class="btn btn-action btn-block" [disabled]="cart.isEmployee" routerLink="/checkout">Check
            Out</button>
        </div>

        <div noScrollBottom>
          <div class="pb-4 mb-4 border-bottom">
            <div>
              <div class="os-continue-btn mt-1 mb-2">
                <a [attr.href]="config.environment.contentSite" class="text-primary font-weight-medium">
                  <i class="lef-chevron-circle-left text-orange"></i> Continue Shopping
                </a>
              </div>
              <div *ngIf="cart.isPremierBannerAvailable" class="flex-fill premier-offer-bx">
                <lef-cart-premier-offer *ngIf="cart.showPremierOffer" [cartTotal]="cart.subTotal"
                                        [isRenewalCandidate]="cart.isRenewalCandidate"
                                        [isVipPremierCustomer]="cart.isVipPremierCustomer"
                                        (premierItemAdded)="onCartUpdated($event)">
                </lef-cart-premier-offer>
              </div>
            </div>
          </div>

          <lef-cart-messages [messages]="cart.messages"></lef-cart-messages>
          <lef-cart-items [items]="cart.items" (updateCart)="updateCart()" (removeItem)="removeItem($event)"
                          (autoShipUpdated)="updateAutoShip($event)" (addToWishListItem)="addToWishListItem($event)">
          </lef-cart-items>
          <div class="mt-5">
            <lef-item-carousel title="Buy Again" carouselId="BuyAgainCarousel" [reload]="reloadCarouselItems"
                               [maxSlidesToShow]="3" (addToCartClicked)="addItemFromCarousel($event)">
            </lef-item-carousel>
          </div>
          <div class="mt-5">
            <lef-item-carousel title="Recommended for You" carouselId="CartCarousel" [reload]="reloadCarouselItems"
                               [maxSlidesToShow]="3" (addToCartClicked)="addItemFromCarousel($event)">
            </lef-item-carousel>
          </div>
        </div>
      </lef-scroll-container>
    </div>
    <div class="col-md-5 col-lg-4 mob-pad-0">
      <lef-scroll-container [scrollOffset]="120" [isResponsive]="true" [minScreenSize]="screenSizes.MD">
        <lef-cart-order-summary [cart]="cart" (cartUpdated)="onCartUpdated($event)" (checkout)="onCheckout()">
        </lef-cart-order-summary>
        <lef-cart-super-sale-banner [enabled]="cart.isSuperSale">
        </lef-cart-super-sale-banner>
      </lef-scroll-container>
    </div>
  </div>
</div>

<ng-template #emptyCart>
  <div class="pb-3">
    <a [attr.href]="config.environment.contentSite" class="text-primary font-weight-bold">
      <i class="lef-chevron-circle-left text-orange"></i> Continue Shopping
    </a>
  </div>
  <lef-cart-messages [messages]="cart.messages"></lef-cart-messages>
  <div class="mt-2 row">
    <lef-item-carousel *ngIf="!isMobile" class="col-12 col-md-9 col-lg-9 mob-pad-0" title="Trending Now"
                       carouselId="EmptyCartCarousel" [maxSlidesToShow]="3"
                       (addToCartClicked)="addItemFromCarousel($event)">
    </lef-item-carousel>
    <div class="col-12 col-md-3 mob-pad-0">
      <div class="bg-lightblue mb-3">
        <a href="{{config.environment.contentSite}}{{config.environment.bestSellersLink}}" target="_blank"
           class="d-block p-3">
          <h3 class="roboto text-primary"> Your Shopping Cart is empty</h3>
          <p class="text-copy font-weight-normal"> Choose from popular categories like Immune Health, Energy, Heart,
            Brain and more. <span class="text-info font-weight-bold">Explore Our Best Seller</span>
          </p>
        </a>
      </div>
      <div class="d-block bg-light p-3">
        <a href="{{config.environment.contentSite}}{{config.environment.supplementQuizLink}}" target="_blank"
           class="pb-wrapper">
          <div class="pb-bg bg-light">
            <div class="d-flex align-items-center mb-2">
              <div class="">
                <img src="https://www.lifeextension.com/-/media/lifeextension/icons/supplement-quizzes/essentials/essentials.svg"
                     width="80" hight="80">
              </div>
              <h3 class="prog-banner-title m-0">
                <strong class="roboto text-uppercase text-info">Essentials</strong><br>
                <span class="zilla text-uppercase text-primary">Quiz</span>
              </h3>
            </div>
            <p class="text-copy font-weight-normal pb-copy">
              Answer a few questions to get custom supplement recommendations.
            </p>
            <p class=" d-flex align-items-center m-0">
              <span class="">Take the Quiz</span><i class="lef-chevron-circle-right text-orange font-lg ml-1"></i>
            </p>
          </div>
        </a>
      </div>
    </div>
    <lef-item-carousel *ngIf="isMobile" class="mt-3 col-12 col-md-9 mob-pad-0" title="Trending Now"
                       carouselId="EmptyCartCarousel" [maxSlidesToShow]="3"
                       (addToCartClicked)="addItemFromCarousel($event)">
    </lef-item-carousel>
  </div>
</ng-template>