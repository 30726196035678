import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { RegistrationComponent } from 'src/app/account/component/registration/registration.component';
import { IRegistration } from 'src/app/account/model';
import { AuthorizationService } from 'src/app/authorization';
import { ResponsiveService } from 'src/app/common/service';
import { AppValidators, ValidationMessages } from 'src/app/shared/form/form-validations';
import { IActionToken } from 'src/app/shared/model/action-token';
import { IListItem } from 'src/app/shared/model/list-item';
import { ActionService } from 'src/app/shared/service/action.service';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { CaptureEmailModel } from 'src/app/tracking/model/capture-email.model';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { LoginModel } from '../../model/login-model.model';
import { AccountVerificationModel } from '../../model/verification-code.model';
import { LoginService } from '../../service/login.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { VerifyAccountCodeComponent } from '../verify-account-code/verify-account-code.component';

@Component({
  selector: 'lef-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() loginUpdate: EventEmitter<LoginModel> = new EventEmitter();
  @Output() marketingCodeRemoved: EventEmitter<string> = new EventEmitter();
  @Output() isSignUp: EventEmitter<boolean> = new EventEmitter();

  @Input() showEmailLabel = false;
  @Input() showWelcomeMessage = false;
  @Input() isCheckoutFlow = false;
  @Input() action: Subject<string> = new Subject();
  @Input() continueAsGuest: Observable<void>;
  continueAsGuestSubscription: Subscription;
  @Input() reset: Observable<void>;
  resetSubscription: Subscription;
  label = 'Email:';
  placeHolder = 'Email Address';
  welcomeMessage = 'Sign-in or Register for a Life Extension website account';
  forgotPasswordLabel = 'I forgot my password';

  loginModel = new LoginModel();
  countries: IListItem[] = [];

  showFormErrorMessage = false;
  errorMessage = '';

  showEmailForm = false;
  emailForm: UntypedFormGroup;

  showPasswordForm = false;
  passwordForm: UntypedFormGroup;

  showNewPasswordForm = false;
  newPasswordForm: UntypedFormGroup;

  showZipVerificationForm = false;
  zipVerificationForm: UntypedFormGroup;

  showCountryVerificationForm = false;
  countryVerificationForm: UntypedFormGroup;
  prompt: string;

  showLoginPassword: boolean = false;
  showRegistrationPassword: boolean = false;
  showConfirmPassword: boolean = false;
  submitEnabled: boolean = true;

  get showResetOption(): boolean {
    if (!this.loginModel) {
      return false;
    }

    const hasEmail = !!this.loginModel.email;
    const isFullLogin = this.loginModel.loginStatus === 'FullLogin';
    const isValidGuestCheckout =
      this.loginModel.isGuestCheckout && (!this.loginModel.isInvalidGuestEmail || this.loginModel.guestEmailConfirmed);

    return hasEmail && !isFullLogin && !isValidGuestCheckout;
  }

  ValidationMessages = ValidationMessages;

  private loginActionToken: IActionToken;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly loginService: LoginService,
    private readonly dialogService: DialogService,
    private readonly cartDialogService: CartDialogService,
    private readonly trackingService: TrackingService,
    private readonly authorizationService: AuthorizationService,
    private readonly loadingService: LoadingService,
    private readonly responsiveService: ResponsiveService,
    private readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly actionService: ActionService
  ) { }

  toggleLoginPasswordVisibility() {
    this.showLoginPassword = !this.showLoginPassword;
  }

  toggleRegistrationPasswordVisibility() {
    this.showRegistrationPassword = !this.showRegistrationPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  ngOnInit() {
    this.generateForms();
    this.subscribeToResetEvents();
    this.subscribeToContinueAsGuestEvents();

    this.route.queryParams.subscribe((params) => {
      let recoveryId = params.prid;
      if (!recoveryId) {
        this.getCurrentUser();
        this.getLoginToken();
      }
    });

    this.responsiveService.checkCurrentSize();
  }

  ngOnDestroy() {
    this.unsubscribeToRestEvents();
    this.unsubscribeToContinueAsGuestEvents();
  }

  showForgotPasswordDialog() {
    this.showLoginPassword = false;
    const dialogRef = this.dialogService.open({
      title: 'Password Assistance',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ForgotPasswordComponent,
    });

    const componentRef = dialogRef.content.instance;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });
  }

  submitEmailForm() {
    this.emailForm.controls.email.setValue(this.emailForm.value.email.trim());

    if (!this.emailForm.valid) {
      this.showFormErrorMessage = true;
      if (this.emailForm.controls.email.errors?.email) {
        this.errorMessage = 'Invalid e-mail address.';
      }

      return;
    }

    this.loadingService.show();
    const model = { ...this.loginModel };
    model.email = this.emailForm.value.email;
    model.isCheckoutFlow = this.isCheckoutFlow;
    this.submitEnabled = false;
    this.loginService.validateEmail(model).subscribe(
      (result) => {
        this.processLoginResponse(result);
        this.submitEnabled = true;
        this.loadingService.hide();
      },
      (error) => {
        this.submitEnabled = true;
        this.loadingService.hide();
      });
  }

  submitZipForm() {
    if (!this.zipVerificationForm.valid) {
      return;
    }

    this.loginModel.billingZipCode = this.zipVerificationForm.value.zipCode;
    this.login();
  }

  submitCountryForm() {
    if (!this.countryVerificationForm.valid) {
      return;
    }

    this.loginModel.billingCountry = this.countryVerificationForm.value.country;
    this.login();
  }

  submitLoginForm() {
    if (!this.passwordForm.valid) {
      return;
    }

    this.loginModel.password = this.passwordForm.value.password;
    this.login();
  }

  submitNewPasswordForm() {
    if (!this.newPasswordForm.valid) {
      return;
    }
    this.isSignUp.emit(true);

    this.loginModel.password = this.newPasswordForm.value.password;
    if (this.isCheckoutFlow) {
      this.sendCaptureEmail('Checkout');
      if (this.loginModel.isVerifiedCustomer) {
        this.login();
      } else {
        this.guestCheckOut();
      }
    } else {
      this.sendCaptureEmail('direct signup');
      this.startRegistrationProcess();
    }
  }

  resetForms() {
    this.processLoginResponse(new LoginModel());
    this.loginUpdate.emit(this.loginModel);
  }

  private subscribeToResetEvents() {
    if (this.reset) {
      this.resetSubscription = this.reset.subscribe(() => this.resetForms());
    }
  }

  private subscribeToContinueAsGuestEvents() {
    if (this.continueAsGuest) {
      this.continueAsGuestSubscription = this.continueAsGuest.subscribe(() => this.guestCheckOut());
    }
  }

  private unsubscribeToRestEvents() {
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.resetSubscription = null;
    }
  }

  private unsubscribeToContinueAsGuestEvents() {
    if (this.continueAsGuestSubscription) {
      this.continueAsGuestSubscription.unsubscribe();
      this.continueAsGuestSubscription = null;
    }
  }

  private guestCheckOut() {
    this.loadingService.show('Please Wait...');
    this.loginService.guestCheckOut(this.loginModel).subscribe({
      next: (result) => {
        if (this.loginModel?.isProspect === false && result?.isProspect === true) this.trackingService.generateLead.next();
        this.processLoginResponse(result);
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  private guestCheckOutConfirmEmail() {
    this.loginService.guestCheckOutConfirmEmail(this.loginModel).subscribe((result) => {
      this.processLoginResponse(result);
    });
  }

  private login() {
    this.errorMessage = '';

    this.loginModel.isCheckoutFlow = this.isCheckoutFlow;
    this.loadingService.show('Logging In...');
    this.submitEnabled = false;
    if (!this.loginActionToken.token || this.loginActionToken.expiration < Date.parse(new Date().toString())) {
      this.getLoginToken(true);
    } else {
      this.doLogin();
    }
  }

  private getLoginToken(doLogin: boolean = false) {
    this.actionService.GetActionToken('LoginUser').subscribe({
      next: (result) => {
        this.loginActionToken = result;
        if (doLogin) {
          this.doLogin();
        }
      },
      error: (error) => {
        this.loadingService.hide();
        this.submitEnabled = true;
      },
    });
  }

  private doLogin() {
    this.actionService.SetCurrentIntent(this.loginActionToken.token);
    this.loginService.login(this.loginModel).subscribe({
      next: (result) => {
        this.processLoginResponse(result);
        this.loadingService.hide();
        this.submitEnabled = true;
      },
      error: (error) => {
        if (error?.content?.errorMessage) {
          this.showFormErrorMessage = true;
          this.errorMessage = error.content.errorMessage;
        }
        this.loadingService.hide();
        this.submitEnabled = true;
      },
    });
  }

  private startRegistrationProcess() {
    this.showRegistrationDialog();
  }

  private showRegistrationDialog() {
    const registrationModel: IRegistration = {
      email: this.loginModel.email,
      customerNumber: this.loginModel.customerNumber,
      password: this.loginModel.password,
    };

    const dialogRef = this.dialogService.open({
      title: 'Personal Information',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: RegistrationComponent,
    });

    const componentRef: RegistrationComponent = dialogRef.content.instance;
    componentRef.model = registrationModel;

    componentRef.registrationComplete.subscribe((model: IRegistration) => {
      dialogRef.close();
      this.getCurrentUser(true);
    });

    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });
  }

  private showEmailVerificationDialog() {
    this.cartDialogService.showEmailVerificationDialog(this.loginModel.email, (confirm) => {
      if (confirm) {
        this.loginModel.guestEmailConfirmed = true;
        this.guestCheckOutConfirmEmail();
      } else {
        this.resetForms();
      }
    });
  }

  private showAccountVerificationDialog() {
    const verificationModel = new AccountVerificationModel();
    verificationModel.email = this.loginModel.email;

    const dialogRef = this.dialogService.open({
      title: 'Please Verify Your Account',
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: VerifyAccountCodeComponent,
    });

    const componentRef = dialogRef.content.instance;
    componentRef.verificationModel = verificationModel;

    componentRef.accountVerified.subscribe((verificationModel: AccountVerificationModel) => {
      if (verificationModel.isValid) {
        this.loginModel.customerNumber = verificationModel.customerNumber;
        this.loginModel.isVerifiedCustomer = verificationModel.isValid;
        this.loginModel.allowContinueAsGuest = !verificationModel.isValid;
        this.loginModel.promptForAccountVerification = false;
        this.processLoginResponse(this.loginModel);
      }
    });

    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });
  }

  private generateForms(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, AppValidators.notNumber]],
    });

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
    });

    this.newPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          AppValidators.passwordMinLength,
          AppValidators.passwordStrength,
          AppValidators.passwordMismatchTrigger('passwordConfirmation'),
        ],
      ],
      passwordConfirmation: ['', [Validators.required, AppValidators.passwordMismatch('password')]],
    });

    this.zipVerificationForm = this.formBuilder.group({
      zipCode: ['', [Validators.required]],
    });

    this.countryVerificationForm = this.formBuilder.group({
      country: ['', [Validators.required]],
    });
  }

  private getCurrentUser(afterRegistration = false) {
    this.loginService.getCurrentUser(this.isCheckoutFlow).subscribe((response) => {
      this.processLoginResponse(response, afterRegistration);
    });
  }

  private processLoginResponse(response: LoginModel, afterRegistration = false) {
    if (!response) {
      return;
    }

    this.setPrompt();

    if (this.loginModel.loginStatus !== response.loginStatus) {
      this.authorizationService.authorizationChange.next(null);
    }

    this.loginModel = response;
    this.showFormErrorMessage = false;
    this.errorMessage = '';

    // Process Error Messages
    if (response.errorMessage) {
      this.showFormErrorMessage = true;
      this.errorMessage = response.errorMessage;
    }

    // Send Object to Parent
    this.loginUpdate.emit(this.loginModel);
    if (afterRegistration) {
      this.trackingService.customerRegistration.next(this.loginModel.customerNumber);
      this.trackingService.signUp.next(this.prompt);
    }

    // Ask for Email
    if (!this.loginModel || !this.loginModel.email || !this.loginModel.isValidEmail) {
      this.showLoginPassword = false;
      this.showRegistrationPassword = false;
      this.showConfirmPassword = false;
      this.toggleForm('Email');
      return;
    }

    // Ask for Zip Code
    if (this.loginModel.promptForZip) {
      this.toggleForm('Zip');
      return;
    }

    // Ask for Country
    if (this.loginModel.promptForCountry) {
      this.toggleForm('Country');
      return;
    }

    // Ask for Account Verification
    if (this.loginModel.promptForAccountVerification) {
      this.toggleForm('Email');
      this.showAccountVerificationDialog();
      return;
    }

    if (this.loginModel.marketingCodeRemoved) {
      this.marketingCodeRemoved.emit('');
    }

    // User is Logged In
    if (this.loginModel.loginStatus === 'FullLogin') {
      if (!afterRegistration && this.loginModel?.success && this.loginModel?.isExistingCustomerWithWebAccount)
        this.trackingService.logIn.next(this.prompt);
      this.toggleForm('');
      return;
    }

    // Guest Checkout Ready to Go
    if (
      this.isCheckoutFlow &&
      this.loginModel.isGuestCheckout &&
      (!this.loginModel.isInvalidGuestEmail || this.loginModel.guestEmailConfirmed)
    ) {
      this.toggleForm('');
      return;
    }

    // Ask for Password
    if (this.loginModel.isExistingCustomerWithWebAccount) {
      this.toggleForm('Password');
      return;
    }

    // Ask for Guest Email Verification
    if (this.loginModel.isInvalidGuestEmail) {
      this.showEmailVerificationDialog();
      return;
    }

    // Ask New Password and Register
    if (this.loginModel.isValidEmail) {
      this.toggleForm('NewPassword');
      return;
    }
  }

  private toggleForm(form: '' | 'Email' | 'Password' | 'NewPassword' | 'Zip' | 'Country') {
    if (form === 'Email') {
      this.showEmailForm = true;
    } else {
      this.showEmailForm = false;
      this.emailForm.reset();
    }

    if (form === 'Password') {
      this.showPasswordForm = true;
    } else {
      this.showPasswordForm = false;
      this.passwordForm.reset();
    }

    if (form === 'NewPassword') {
      this.showNewPasswordForm = true;
    } else {
      this.showNewPasswordForm = false;
      this.newPasswordForm.reset();
    }

    if (form === 'Zip') {
      this.showZipVerificationForm = true;
    } else {
      this.showZipVerificationForm = false;
      this.zipVerificationForm.reset();
    }

    if (form === 'Country') {
      this.commonService.getCountryList().subscribe((countries: IListItem[]) => {
        this.countries = countries;
        this.showCountryVerificationForm = true;
      });
    } else {
      this.showCountryVerificationForm = false;
      this.countryVerificationForm.reset();
    }
  }

  private sendCaptureEmail(vehicle: string): void {
    let captureEmail = new CaptureEmailModel();

    captureEmail.vehicle = vehicle;

    captureEmail.status = 'New';
    if (this.loginModel.isExistingCustomer) {
      captureEmail.status = 'Customer';
    } else if (this.loginModel.isProspect) {
      captureEmail.status = 'Prospect';
    }

    this.trackingService.captureEmail.next(captureEmail);
  }

  private setPrompt(): void {
    this.prompt = 'direct';
    if (this.route.snapshot.queryParamMap.get('returnUrl')) this.prompt = 'required';
    if (this.isCheckoutFlow && this.prompt === 'direct') this.prompt = 'checkout';
  }
}
