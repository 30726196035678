<div #floatingCartContent class="dropdown" aria-expanded="false" [ngClass]="{'co-floating-cart': !showHeaders }">
    <div id="dropdownFloatCart" class="view-floating-cart refresh-total" data-toggle="dropdown" data-api-url=""
         data-container="containerShopingCart" aria-expanded="false" (click)="viewFloatingCart()">
        <button class="btn-cart hdr-nav-icon">
            <i class="lef-cart-custom-new"></i>
            <span id="cartItemCount" class="cartItemCount cart-count">{{itemCount}}</span>
            <p class="hdr-nav-icon-text text-primary">Cart</p>
        </button>
    </div>
    <div id="containerShopingCart" class="dropdown-menu dropdown-menu-right floating-cart ">
        <div *ngIf="isLoadingFloatingCart" class='text-center w-100 text-primary font-weight-bold'>Loading...</div>
        <ng-container *ngIf="!isLoadingFloatingCart">
            <div class="floating-cart-item-wrap">
                <p *ngIf="floatingCart.TotalItemsCount == 0">There are currently no items in your cart.</p>
                <div *ngFor="let item of floatingCart.Items" class='floating-cart-item'>
                    <div class='floating-cart-image'>
                        <img src='{{item.ImagePath}}'>
                    </div>
                    <div class='floating-cart-descrip'>
                        <p>
                            <a href='{{item.ItemUrl}}'> {{item.ItemDescription}}</a><br>
                            <span class='text-copy'> Quantity: {{item.Quantity}}</span>
                        </p>
                        <p class='text-right text-copy'>
                            <strong> {{item.YourPrice}} </strong>
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="floatingCart.HasMoreItems" class="additional-products-link text-center pb-3">
                <a href="" routerLink="/" class="btn btn-link text-primary">Additional Product(s) in Your
                    Cart</a>
            </div>
            <div *ngIf="floatingCart.TotalItemsCount > 0" class="floating-items-total">
                <div>
                    <dl class="text-copy">
                        <dt>Items Total</dt>
                        <dd>{{floatingCart.ItemsTotal}}</dd>
                    </dl>
                    <dl class="text-copy">
                        <dt>Savings</dt>
                        <dd>{{floatingCart.TotalSavings}}</dd>
                    </dl>
                    <dl class="float-cart-subtotal text-primary">
                        <dt><strong>Subtotal</strong></dt>
                        <dd><strong>{{floatingCart.SubTotal}}</strong></dd>
                    </dl>
                </div>
            </div>
        </ng-container>
        <div class="row floating-cart-controls">
            <div class="col-6">
                <button routerLink="/orderbynumber" class="btn btn-primary btn-block mb-2">Order By Item#</button>
                <button class="btn btn-primary btn-block mb-2" (click)="findMyOrder()">Find My Order</button>
            </div>
            <div class="col-6">
                <button routerLink="/" class="btn btn-primary btn-block">View My Cart</button>
                <button routerLink="/checkout" class="btn btn-action btn-block">
                    Checkout <i class="lef lef-chevron-right pl-1"></i>
                </button>
            </div>
        </div>
    </div>
</div>