<div class="find-my-order">
    <div *ngIf="!showOrderDetails" class="row">
        <div class="col-md-6 mb-3 mb-md-0">
            <h1 class="h2">Look up a Single Order</h1>
            <p>* Indicate required field</p>
            <div *ngIf="orderValidationFails" class="alert alert-warning" role="alert">
                <i class="lef-warning"></i>
                <span>The information you have entered does not match our records. Please try again or for additional
                    help
                    call 1-800-678-8989</span>
            </div>
            <form class="k-form" [formGroup]="searchForm" (ngSubmit)="findMyOrder(searchForm.value)">
                <div class="form-group">
                    <input kendoTextBox class="form-control login-email" placeholder="Last Name or Company Name*"
                           formControlName="name" aria-label="Enter last name or company name" />
                </div>
                <div class="form-group">
                    <input kendoTextBox class="form-control login-email" placeholder="Order #*"
                           formControlName="orderNumber" aria-label="Enter order number" />
                </div>
                <div class="form-group">
                    <button type="submit" [disabled]="!searchForm.valid" class="btn btn-warning">Look Up Order</button>
                </div>
            </form>
            <p>Your order number can be found on the email you received when you placed your order or on the packing
                slip
                that was included in your shipment.
                If you no longer have the email or packing slip, please call 1-800-678-8989 for assistance.</p>
        </div>

        <div class="col-md-6">
            <h2>View Full Order History</h2>
            <p>If you have a web account you can sign in here to see your full order history.</p>
            <button class="btn btn-warning" routerLink="/account/orderhistory">Sign In</button>
        </div>
    </div>

    <lef-order-details *ngIf="showOrderDetails" [orderNumber]="orderNumber" [embedded]="true"
                       (hideDetails)="onHideDetails()">

    </lef-order-details>
</div>