<div id="mainHeader" class="d-print-none le-header" [ngClass]="{'co-le-header': !showHeaders }">
  <a href="#content" class="ada-link-wrap" tabindex="0">Skip to content</a>
  <a href="#footer" class="ada-link-wrap">Skip to footer</a>
  <div class="header-nav-bar-cart main-nav-wrapper w-100 bg-white">

    <!-- Desktop header -->
    <div class="desktop-nav-header header-nav-bar-tabs">
      <div class="header-wrap" [ngClass]="{'header-co-wrap': !showHeaders }">
        <div class="header-lt" [ngClass]="{'co-header-lt': !showHeaders }">
          <div class="navbar-brand">
            <div class="mobile-logo d-xl-none">
              <a [attr.href]="baseUrl">
                <img [attr.src]="mobileNavLogoUrl" class="image img-fluid" alt="Life Extension" />
              </a>
            </div>
            <div class="logo d-none d-xl-block">
              <a [attr.href]="baseUrl" tabindex="3">
                <img [attr.src]="webNavLogoUrl" [ngClass]="webImgClass"
                     alt="Life Extension: The Science of a Healthier Life – Homepage" />
              </a>
            </div>
          </div>
        </div>
        <div class="header-ct" [ngClass]="{'co-header-ct': !showHeaders }">
          <div class="d-flex header-ct-wrap">
            <div class="flex-auto d-flex">
              <div class="mr-1" [ngClass]="{'d-none': !showHeaders }">
                <button onclick="openSideMenuNavigation('sideMenuNavigationMain')" class="menu-btn mainsidemenubutton">
                  <i class="lef-bars"></i><span>Menu</span>
                </button>
                <!-- Side menu -->
                <div id="sideMenuNavigationMain" class="sidenav">
                  <div class="sidenav-header">
                    <div id="menuAnonyous" *ngIf="!username">
                      <a routerLink="/login" (click)="closeSideMenu()" class="sidenav-login">
                        Sign In/Join <i class="lef-chevron-right"></i>
                      </a>
                    </div>
                    <div id="menuUserLoggedIn" class="menu-user-logged-in" *ngIf="username">
                      <lef-header-account [username]="username" [menuOnHeader]="false" [menuLinks]="accountMenuLinks"
                                          (linkClick)="closeSideMenu()">
                      </lef-header-account>
                    </div>
                    <a href="javascript:void(0)" class="closebtn" onclick="closeAllSideMenuNavigation()"><i
                         class="lef-close"></i></a>
                  </div>
                  <div class="side-nav-content-wrap sidenav-menu-wrapper">
                    <lef-rendering-content [htmlContent]="menu"></lef-rendering-content>
                  </div>
                </div>
                <!-- Side menu end -->
                <div id="menuOverlay" class="overlay" style="display: none;" onclick="closeAllSideMenuNavigation()">
                </div>
              </div>

              <div class="flex-auto hdr-search-nav-wrap">
                <div class="search-row-wrap w-100">
                  <div class="search-row" [ngClass]="{'d-none': !showHeaders }">
                    <div class="search">
                      <div class='CoveoSearchbox' #mainSearchBox data-enable-partial-match='true'
                           data-enable-query-syntax='true' data-enable-question-marks='true'
                           data-enable-wildcards='true'>
                      </div>
                    </div>
                    <div class="consumer-seal-mob d-xl-none">
                      <a href="" target="_blank"><img src="../../../../assets/images/ftr-consumerlab-seal-2021.png"
                             alt="Consumer Lab Seal Award 2022" /></a>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div id="mainNavBar" class="navbar main-navbar">
            <div [ngClass]="{'d-none': !showHeaders }">
              <lef-rendering-content [htmlContent]="mainNavBar"></lef-rendering-content>
            </div>
          </div>
        </div>
        <div class="header-rt" [ngClass]="{'co-header-rt': !showHeaders }">
          <!-- Start icon menu -->
          <div class="hdr-action-icons">
            <div [ngClass]="{'d-none': !showHeaders }">
              <div class="d-flex align-items-center">
                <div [ngClass]="{'d-lg-none': !!username}">
                  <a routerLink="/account/home" class="hdr-nav-icon">
                    <i class="lef-user-outline"></i>
                    <span>Account</span>
                  </a>
                </div>
                <div class="d-none d-lg-block">
                  <lef-header-account [username]="username" [menuOnHeader]="true"
                                      [menuLinks]="accountMenuLinks"></lef-header-account>
                </div>
                <a [href]="contactUrl" class="hdr-nav-icon">
                  <i class="lef-contact-phone-email"></i>
                  <span>Contact</span>
                </a>
                <div class="myoffers-wrap myoffers-dt" [ngClass]="{'offers-available': userHasOffersAvailable}">
                  <lef-offers-notification offerNotificationId="offersNotificationDT"
                                           (offersCounterUpdated)="onOffersCounterUpdated($event)">
                  </lef-offers-notification>
                </div>
              </div>
            </div>
            <!-- Start Floating Cart -->
            <lef-header-floating-cart></lef-header-floating-cart>
            <!-- End Floating Cart -->
          </div>
          <!-- End icon menu -->
          <div class="desktop-seal banner-top-rt">
            <a [href]="" target="_blank" class="text-primary">
              <img src="../../../../assets/images/ftr-consumerlab-seal-2021.png" alt="Consumer Lab Seal Award 2022" />
              <span aria-hidden="true">#1 Rated<br />
                Catalog/Internet Brand
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Desktop header end -->
  </div>
</div>